const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    valid: false,
});

function validateStep(state, valid) {
    return state.merge({
        valid: valid
    });
}

function hireCardOpenCreditStepINE(state = initialState, action = { type: null }) {

    switch (action.type) {
        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_NEW_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        case actionTypes.HIRE_CARD_VIA_T_NEW_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        case actionTypes.HIRE_NEW_CREDIT_CARD_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        case actionTypes.HIRE_CARD_CREDIT_INIT_SUCCESS: {
            return validateStep(state, action.payload.valid)
        };
        case actionTypes.HIRE_CARD_CREDIT_INIT_ERROR: {
            return validateStep(state, action.payload.valid)
        };
        case actionTypes.HIRE_CARD_CREDIT_INIT_POLICY_SUCCESS: {
            return validateStep(state, action.payload.valid)
        };
        case actionTypes.HIRE_CARD_CREDIT_INIT_POLICY_ERROR: {
            return validateStep(state, action.payload.valid)
        };
        case actionTypes.HIRE_CARD_CREDIT_INE_SUCCESS: {
            return action
        };
        case actionTypes.HIRE_CARD_CREDIT_INE_ERROR: {
            return action
        };
        default:
            return state;
    }
}

module.exports = hireCardOpenCreditStepINE;
